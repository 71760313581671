import request from '@/utils/request'

// 表格数据
export function getList(data) {
  return request({
    url: '/saleAdmin/order/index',
    method: 'post',
    data,
  })
}

// 表格数据详情
export function getDetail(data) {
  return request({
    url: '/saleAdmin/order/view',
    method: 'post',
    data,
  })
}
// 主页下拉菜单
export function getSelect(data) {
  return request({
    url: '/saleAdmin/order-pre/option',
    method: 'post',
    data,
  })
}
export function getSelect2(data) {
  return request({
    url: '/saleAdmin/order/option',
    method: 'post',
    data,
  })
}
// 表格数据   &&&  客户简拼下拉数据
export function getClientSelect(data) {
  return request({
    url: '/baseAdmin/customer-info/window-view-do',
    method: 'post',
    data,
  })
}
/**
 * @作废销售单
 */
export function deleteSaleOrder(data) {
  return request({
    url: '/saleAdmin/order/invalid',
    method: 'post',
    data,
  })
}
/**
 * @汇总派单查询接口
 */
/**
 * @派单汇总
 */
export function summaryOrder(data) {
  return request({
    url: '/saleAdmin/order-pre/delivery-order-record',
    method: 'post',
    data,
  })
}
/**
 * @派单商品汇总
 */
export function summaryGoods(data) {
  return request({
    url: '/saleAdmin/order-pre/delivery-goods-record',
    method: 'post',
    data,
  })
}

/**
 * @单独保存备注接口  销售单
 */
export function saveRemark(data) {
  return request({
    url: '/saleAdmin/order/update-remark',
    method: 'post',
    data,
  })
}

// 修改销售单
export function saveSale(data) {
  return request({
    url: '/saleAdmin/order/update',
    method: 'post',
    data,
  })
}
/**
 * @销售单取消到货
 */
export function saleCancelArrival(data) {
  return request({
    url: '/saleAdmin/order-pre/cancel-complete',
    method: 'post',
    data,
  })
}
